@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bowlby+One+SC&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.main-container {
  @apply max-w-[1290px] mx-auto w-full px-8;
}

.dark-transition {
  @apply transition-all duration-300;
}
.gradient {
  color: linear-gradient(
    to right,
    #eea2a2 0%,
    #bbc1bf 19%,
    #57c6e1 42%,
    #b49fda 79%,
    #7ac5d8 100%
  );
}

.contract-address {
  background-image: url("../img/ContractCard.png") !important;
}

.dark .contract-address {
  background-image: url("../img/ContractCardDark.png") !important;
}

@media (max-width: 767.98px) {
  .dark .contract-address {
    background-image: url("../img/ContractCardMobileDark.png") !important;
  }

  .contract-address {
    background-image: url("../img/contractMobile.png") !important;
  }
}

.why-invest-banner {
  background-image: url("../img/lightbanner.png");
}
.why-invest-mobile {
  background-image: url("../img/why-invest-bg.png");
  /* background: rgba(0, 0, 0, 0.1); */
}
.dark .why-invest-banner {
  background-image: url("../img/darkbanner.png");
  /* background: rgba(0, 0, 0, 0.1); */
}
.shiny-heffers-bg {
  background-image: url("../img/Rectangle116.png");
}
.roadmap-bg {
  background-image: url("../img/Rectangle123.png");
}
.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(231 28 165 / var(--tw-bg-opacity));
}
.swiper-pagination-bullets {
  bottom: -3px !important;
}
.swiper-pagination-bullet-active {
  background: #a0188d !important;
}
.swiper-pagination-bullet {
  border: 1px solid #11024e !important;
  width: 15px !important;
  height: 15px !important;
  background: white;
}
@media (min-width: 768px) {
  .swiper-pagination-bullets {
    display: none !important;
  }
}

.swiper-content {
  width: 100%;
}

@media (min-width: 320px) {
  .meet-team-main {
    width: 250px !important;
  }
}
@media (min-width: 425px) {
  .meet-team-main {
    width: 280px !important;
  }
}
@media (min-width: 768px) {
  .meet-team-main {
    width: auto !important;
  }
}
